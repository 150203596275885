<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="currentColor">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4356 11.3397C15.4648 8.5453 15.2197 4.61271 12.7003 2.09324C9.90929 -0.697745 5.38422 -0.697745 2.59324 2.09324C-0.197745 4.88422 -0.197745 9.40929 2.59324 12.2003C5.11271 14.7197 9.0453 14.9648 11.8397 12.9356L15.2307 16.3265C15.1351 16.5585 15.1049 16.7762 15.17 16.9498C15.474 17.7604 19.375 21.6614 20.1856 21.9653C20.9961 22.2693 22.7693 20.4961 22.4653 19.6856C22.1614 18.875 18.2604 14.974 17.4498 14.67C17.2762 14.6049 17.0585 14.6351 16.8265 14.7307L13.4356 11.3397ZM10.5725 4.22103C12.1883 5.83686 12.1883 8.45664 10.5725 10.0725C8.95664 11.6883 6.33686 11.6883 4.72103 10.0725C3.1052 8.45664 3.1052 5.83686 4.72103 4.22103C6.33686 2.6052 8.95664 2.6052 10.5725 4.22103Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'Search',
}
</script>
