<template>
  <a-modal
    :visible="true"
    :footer="null"
    class="modal-retur"
    @cancel="$emit('handleModal',false)"
  >
    <h3 class="text-center">
      Detail Permintaan Retur
    </h3>
    <div class="d-flex">
      <a-col :span="3">
        No. Request
      </a-col>
      <a-col :span="4">
        123
      </a-col>
    </div>
    <div class="d-flex">
      <a-col :span="3">
        Tgl. Request
      </a-col>
      <a-col :span="4">
        20 OKT 2022
      </a-col>
    </div>
    <div class="d-flex">
      <a-col :span="3">
        Nama Customer
      </a-col>
      <a-col :span="4">
        ABCD
      </a-col>
    </div>

    <!-- DATA --> <!-- LIST -->
    <div class="list my-4">
      <div class="header-list d-flex">
        <a-col :span="1">
          No.
        </a-col>
        <a-col :span="4" class="d-flex justify-content-center">
          Nama Produk
        </a-col>
        <a-col :span="2" class="d-flex justify-content-left">
          Ref Order
        </a-col>
        <a-col :span="1" class="d-flex justify-content-left">
          Qty
        </a-col>
        <a-col :span="3" class="d-flex justify-content-left">
          Harga Retur
        </a-col>
        <a-col :span="2" class="d-flex justify-content-left">
          Total
        </a-col>
        <a-col :span="2" class="d-flex justify-content-left">
          Nilai Koin
        </a-col>
        <a-col :span="5" class="d-flex justify-content-left">
          Total Pengembalian Dana
        </a-col>
        <a-col :span="2" class="d-flex justify-content-left">
          Image
        </a-col>
        <a-col :span="2" class="d-flex justify-content-left">
          Reason
        </a-col>
      </div>
      <div
        v-for="(item, index) in dataRetur"
        :key="index"
        class="data-list p-3"
        :show-arrow="false"
      >
        <a-row>
          <div class="d-flex align-items-center">
            <a-col :span="1">
              {{ index + 1 }}
            </a-col>
            <a-col :span="4" class="d-flex justify-content-center">
              {{ item.product_name }}
            </a-col>
            <a-col :span="2" class="d-flex justify-content-left">
              {{ item.ref_order }}
            </a-col>
            <a-col :span="1" class="d-flex justify-content-left">
              {{ item.qty }}
            </a-col>
            <a-col :span="3" class="d-flex justify-content-left">
              {{ item.retur_price }}
            </a-col>
            <a-col :span="2" class="d-flex justify-content-left">
              {{ item.total }}
            </a-col>
            <a-col :span="2" class="d-flex justify-content-left">
              {{ item.total_koin }}
            </a-col>
            <a-col :span="5" class="d-flex justify-content-left">
              {{ item.total_price_retur }}
            </a-col>
            <a-col :span="2" class="d-flex justify-content-left">
              {{ item.img }}
            </a-col>
            <a-col :span="2" class="d-flex justify-content-left">
              {{ item.reason }}
            </a-col>
          </div>
        </a-row>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <div class="mr-5">
          Total Pengembalian Dana
        </div>
        <div>
          Rp. 25.000
        </div>
      </div>
      <div class="d-flex justify-content-end mt-5">
        <a-button>
          Proses
        </a-button>
        <a-button class="mx-4">
          Tolak
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data: function() {
    return {
      dataRetur: [
        {
          product_name: 'Cap Kaki 3',
          ref_order: 'A123123',
          qty: '10',
          retur_price: '25000',
          total: '25000',
          total_koin: '350',
          total_price_retur: '24560',
          img: '',
          reason: 'Rusak',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.modal-retur {
  .list {
  // border: 1px solid #999;
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  .ant-modal {
    width: 75% !important;
  }
}
</style>