<template>
  <div class="pbz-font d-flex flex-column justify-content-center">
    <div class="d-flex justify-content-Start">
      <h3>List Pengajuan Retur</h3>
    </div>

    <div class="retur-container d-flex flex-column justify-content-center">
      <div class="content">
        <a-row class="d-flex align-items-center">
          <a-col :span="12" class="d-flex">
            <div class="search-box mx-2">
              <a-input
                type="text"
                :placeholder="$t('userManage.search')"
              />
            </div>
            <SearchIcon class="mx-3" style="cursor: pointer" />
          </a-col>
        </a-row>

        <!-- LIST -->
        <div class="list my-4">
          <div class="header-list d-flex">
            <a-col :span="1">
              No.
            </a-col>
            <a-col :span="5" class="d-flex justify-content-center">
              No. Req. Retur
            </a-col>
            <a-col :span="4" class="d-flex justify-content-center">
              Tanggal Request
            </a-col>
            <a-col :span="5" class="d-flex justify-content-center">
              Customer
            </a-col>
            <a-col :span="5" class="d-flex justify-content-center">
              Status
            </a-col>
          </div>
          <div
            v-for="(item, index) in dataRetur"
            :key="index"
            class="data-list p-3"
            :show-arrow="false"
          >
            <a-row>
              <div class="d-flex align-items-center">
                <a-col :span="1">
                  {{ index + 1 }}
                </a-col>
                <a-col :span="5" class="d-flex justify-content-center">
                  {{ item.no_req }}
                </a-col>
                <a-col :span="4" class="d-flex justify-content-center">
                  {{ $moment(item?.req_date).format('DD MMM YYYY HH:mm') }}
                </a-col>
                <a-col :span="5" class="d-flex justify-content-center">
                  {{ item.customer }}
                </a-col>
                <a-col :span="5" class="d-flex justify-content-center">
                  {{ item.status }}
                </a-col>
                <a-col :span="1" class="d-flex justify-content-center ml-5">
                  <a-button @click="handleModal(true)">
                    {{ item.status?.toLocaleLowerCase() == 'dalam proses' ? 'Submit' : 'Konfirmasi' }}
                  </a-button>
                </a-col>
              </div>
            </a-row>
          </div>
        </div>
      </div>
    </div>

    <ModalRetur 
      v-if="showModal"
      @handleModal="handleModal"
    />
  </div>
</template>

<script>
  import SearchIcon from '@/components/Icons/Search.vue'
  import ModalRetur from '@/views/retur-channel/submission/ModalRetur.vue'

export default {
  components: {
    SearchIcon,
    ModalRetur,
  },
  data: function() {
    return {
      dataRetur: [
        {
          no_req: 'A003',
          req_date: '2 Okt 2022',
          customer: 'Cust A',
          status: 'Menunggu Konfirmasi',
        },
        {
          no_req: 'A005',
          req_date: '4 Okt 2022',
          customer: 'Cust B',
          status: 'Dalam Proses',
        },
      ],
      showModal: false,
    }
  },
  methods: {
    handleModal(param) {
      this.showModal = param
    },
  },

}
</script>

<style lang="scss">
.retur-container {
  
  .content {
    width: 100%;
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    border-top-left-radius: 0;

    .search-box {
    width: 228px;
    }
    .list {
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    }
    .header-list {
      font-weight: 900;
      background: #E7ECEF;
      border-radius: 5px ;
      padding: 10px;
    }
    .data-list {
      padding: 5px 0;
      margin: 10px 0;
      background: #fff;
      border-radius: 5px ;
    }

    // ANT DESIGN
    .ant-collapse {
      border: none
    }
  }
}
</style>